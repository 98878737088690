* {
  font-family: "Novozymes";
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Novozymes";
  font-weight: normal;
  src: url(https://static-assets.novozymes.com/Novozymes-Regular.woff)
    format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Novozymes";
  font-weight: bold;

  src: url(https://static-assets.novozymes.com/Novozymes-Bold.woff)
    format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Novozymes";
  font-weight: 300;
  src: url(https://static-assets.novozymes.com/Novozymes-Thin.woff)
    format("woff");
  font-display: swap;
}
